import {useAuthStore} from "../stores/authStore";
import {deviceRoot} from "../vue-client";
import dayjs from "dayjs";
import {usePreferenceStore} from "../stores/preferenceStore";

/**
 * add exclusionData
 * @param slotId
 * @param start_date
 * @param end_date
 * @returns {Promise<any>} {_id:xxx,start_date:xxx,end_date:xxx}
 */
export async function submitExclusionData(slotId,start_date,end_date) {
    const authStore = useAuthStore();
    let authString = "Bearer " + authStore.authUser.authToken;
    const response = await fetch(`${deviceRoot}/exclusions/slots/${slotId}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: authString,
        },
        body:JSON.stringify({
            start_date:dayjs(start_date),
            end_date:dayjs(end_date)
        })
    });
    return await response.json();
}

/**
 * query exclusion Data List
 * @param slotId
 * @returns {Promise<any>} [{_id:xxx,start_date:xxx,end_date:xxx}]
 */
export async function queryExclusionDataList(slotId) {
    const authStore = useAuthStore();
    let authString = "Bearer " + authStore.authUser.authToken;
    const response = await fetch(`${deviceRoot}/exclusions/slots/${slotId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: authString,
        }
    });
    return await response.json();
}

/**
 * delete exclusion data
 * @param slotId
 * @param exclusionId _id
 * @returns {Promise<void>}
 */
export async function deleteExclusionData(slotId,exclusionId){
    const authStore = useAuthStore();
    let authString = "Bearer " + authStore.authUser.authToken;
    let fetchObj = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: authString,
        },
    };

    let fetchString = `${deviceRoot}/exclusions/${exclusionId}`;
    await fetch(fetchString, fetchObj);
}

/**
 * update off state
 * @param on
 * @returns {Promise<void>}
 */
export async function updateExclusionOff(on) {
    const preferenceStore = usePreferenceStore();
    const patchBody = {
        qualityControlOn: on,
    }
    await preferenceStore.updatePreference(patchBody);
}

export function filterExclusionData(newDataArray,exclusionData) {
    if (!exclusionData || exclusionData.length === 0) {
        return
    }
    const utcTime = newDataArray.find((x) => x.sensorName === "utcTime").history;
    let result = []
    let temp = []
    utcTime.forEach((item,index) => {
        let flag = exclusionData.some(el => {
            let s_timestamp = dayjs(el.start_date).valueOf()
            let e_timestamp = dayjs(el.end_date).valueOf()
            return item*1000 >= s_timestamp && item*1000 <= e_timestamp
        })
        if (flag) {
            temp.push(index)
        }else if (temp.length > 0) {
            result.push([...temp])
            temp= []
        }
    })
    if (result.length === 0) {
        return;
    }
    let pcData = newDataArray.find((x) => x.sensorName === 'phycocyanin').history
    let caData = newDataArray.find((x) => x.sensorName === 'chlorA').history
    let tuData = newDataArray.find((x) => x.sensorName === 'turbidity').history
    for (let i = 0; i < result.length ; i++) {
        let start_pc = pcData[result[i][0]]
        let start_ca = caData[result[i][0]]
        let start_tu = tuData[result[i][0]]
        let count = result[i].length
        let end_pc = pcData[result[i][count-1]]
        let end_ca = caData[result[i][count-1]]
        let end_tu = tuData[result[i][count-1]]
        for (let j = 1; j < count-1; j++){
            pcData[result[i][j]] = start_pc + (end_pc - start_pc)/(count - 1) * j
            caData[result[i][j]] = start_ca + (end_ca - start_ca)/(count - 1) * j
            tuData[result[i][j]] = start_tu + (end_tu - start_tu)/(count - 1) * j
        }
    }
}

/**
 * update spikeRemoval value
 * @param value
 * @returns {Promise<void>}
 */
export async function updateSpikeRemovalThreshold(value) {
    const preferenceStore = usePreferenceStore();
    const patchBody = {
        spikeRemovalThreshold: value,
    }
    await preferenceStore.updatePreference(patchBody);
}