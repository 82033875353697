export function getImage(eventType) {
    const img = new Image();
    img.eventType = eventType
    if (eventType === 'Rain') {
        // img.src = 'https://cdnjs.cloudflare.com/ajax/libs/fontisto/3.0.4/icons/weather/rain.png';
        img.src = '/assets/rain_wind_weather_icon.png';
        return img;
    }
    else if (eventType === 'Treatment'){
        img.src = '/assets/chemical_icon.png';
        return img;
    }
    else if (eventType === 'Custom'){
        img.src = '/assets/edit.png';
        return img;
    }
    else {
        return undefined;
    }
  }