<template>
  <div style="display: flex;flex-direction: row;width: 100%">
    <div v-if="zoomEnable" class="left-parent">
      <div class="reset" @click="reset">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1590"><path d="M958.72 225.856l-82.688 211.136-13.76 35.2c-3.776 9.728-14.784 14.528-24.512 10.688l-35.2-13.76L591.36 386.368C581.632 382.528 576.832 371.584 580.672 361.856l13.76-35.2c3.776-9.728 14.784-14.528 24.512-10.688l174.912 68.544c-50.56-124.416-171.584-212.672-314.112-212.672-187.84 0-340.16 152.32-340.16 340.16s152.256 340.16 340.16 340.16c148.032 0 273.6-94.72 320.384-226.752l79.296 0c-49.408 174.4-209.408 302.336-399.68 302.336C250.112 927.744 64 741.632 64 512s186.112-415.744 415.744-415.744c157.056 0 293.376 87.296 363.968 215.872l44.608-113.856c3.776-9.728 14.784-14.528 24.512-10.688l35.2 13.76C957.696 205.184 962.496 216.128 958.72 225.856z" p-id="1591"></path></svg>
      </div>
      <div class="bottom-parent">
        <div class="add" @click="zoomIn">
          +
        </div>
        <div class="line"></div>
        <div class="minus" @click="zoomOut">
          -
        </div>
      </div>
    </div>
    <Line
        v-if="chartdata"
        :chartData="chartdata"
        :chartOptions="options"
        :chartId="chartId"
        :width="width"
        :height="height"
        :cssClasses="cssClasses"
        :styles="styles"
        :plugins="plugins"
        ref="lineChart"
        style="flex-grow: 1;touch-action: none;user-select:none;"
    >
    </Line>
  </div>
</template>

<script setup lang="ts">
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import {onMounted, ref,useTemplateRef} from "vue";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    annotationPlugin,
    zoomPlugin
)

const props = defineProps({
  chartId: {
    type: String,
    default: 'line-chart'
  },
  width: {
    type: Number,
    default: 400
  },
  height: {
    type: Number,
    default: 400
  },
  cssClasses: {
    default: '',
    type: String
  },
  styles: {
    type: Object,
    default: () => {
    }
  },
  plugins: {
    type: Array,
    default: () => []
  },
  chartdata: {
    type: Object,
    default: null
  },
  options: {
    type: Object,
    default: null
  },
  zoomEnable: {
    default: false,
    type: Boolean
  }
});

const lineChart = useTemplateRef('lineChart')
const maxValue = {
  y_axis_1_max:0,
  y_axis_2_max:0,
  init:false
}
onMounted(() => {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault(); // 禁止页面滑动，允许图表缩放
    }
  }, { passive: false });
  if (props.zoomEnable) {
    if (lineChart.value && lineChart.value.chart) {
      const chartInstance = lineChart.value.chart;
      chartInstance.options.plugins.zoom = {
        pan:{
          enable:true,
          mode:'y'
        },
        zoom:{
          wheel:{
            enable:true,
          },
          pinch:{
            enable: true
          },
          mode:'y',
          onZoom:({chart}) => {
            chart.options.scales.y_axis_1.min = 0
            chart.options.scales.y_axis_2.min = 0
            chart.update()
          }
        },
      }
    }
  }
})
function zoomIn() {
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      if (!maxValue.init) {
        maxValue.init = true
        maxValue.y_axis_1_max = chartInstance.options.scales.y_axis_1.max
        maxValue.y_axis_2_max = chartInstance.options.scales.y_axis_2.max
      }
      chartInstance.zoom(1.2)
    }
  }
}
function zoomOut(){
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      if (!maxValue.init) {
        maxValue.init = true
        maxValue.y_axis_1_max = chartInstance.options.scales.y_axis_1.max
        maxValue.y_axis_2_max = chartInstance.options.scales.y_axis_2.max
      }
      chartInstance.zoom(0.8)
    }
  }
}
function reset() {
  if (!maxValue.init) {
    return
  }
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      chartInstance.options.scales.y_axis_1.max = maxValue.y_axis_1_max
      chartInstance.options.scales.y_axis_2.max = maxValue.y_axis_2_max
      chartInstance.update()
    }
  }
}

function updateChart(){
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      chartInstance.update()
    }
  }
}

defineExpose({
  updateChart
})
</script>
<style scoped>
  .left-parent{
    height: 100%;
    font-size:30px;
    padding: 0 0 0 10px;
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
  }
  .reset{
    width: 29px;
    height: 29px;
    font-size: 20px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color .16s ease-out;
    user-select: none; /* 禁止选中文本 */
    -webkit-user-select: none; /* 兼容 Chrome/Safari */
  }
  .bottom-parent{
    width: 29px;
    height: 59px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 135px;
  }
  .add{
    width: 29px;
    height: 29px;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
    line-height: 29px;
    cursor: pointer;
    transition: background-color .16s ease-out;
    user-select: none; /* 禁止选中文本 */
    -webkit-user-select: none; /* 兼容 Chrome/Safari */
  }
  .add:active,.reset:active,.minus:active{
    background-color: #009ad6;
  }
  .line{
    width: 20px;
    height: 1px;
    background-color: #80808025;
  }
  .minus {
    width: 29px;
    height: 29px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    line-height: 29px;
    cursor: pointer;
    transition: background-color .16s ease-out;
    user-select: none; /* 禁止选中文本 */
    -webkit-user-select: none; /* 兼容 Chrome/Safari */
  }
</style>
