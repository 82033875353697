<template>
    <div v-for="trackerGroup in trackerGroups" class="col-12 cust-col">
        <div class="card chart">
            <LineChart :chartdata="trackerGroup.PlotData" :options="trackerGroup.PlotOptions" :plugins="[LinePlugin]"
                style="height:300px;" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {defineProps, ref, defineExpose, watch, onMounted} from 'vue'
import LinePlugin from "../plugins/LinePlugin";
import LineChart from "./LineChart.vue";
import { ITrackerData } from '../models/tracker-data';
import { TrackerGroup } from '../models/tracker-group.js';
import dayjs from "dayjs";
const trackerGroups = ref<TrackerGroup[]>([]); // trackerGroups
import { getImage } from "../utilities/annotationImages";

const props = defineProps({
    feature: { type: Number, required: true },
    trackerTraces: { type: Array<ITrackerData>, required: true },
    device: { type: Object },
    eventTable: { type: Array },
})

let annotationId = 0

watch(() => props.eventTable, (newValue) => {
  updateAnnotations()
},{deep: true})

const initializeTrackerGroups = async(device: any, trackerTraces: Array<ITrackerData>) => {
    props.device = device;
    props.trackerTraces = trackerTraces;
    props.feature = device.feature;
    trackerGroups.value = [new TrackerGroup(trackerTraces, device.feature, device)];
    updateAnnotations()
};

function updateAnnotations() {
  trackerGroups.value.forEach((trackerGroup: TrackerGroup) => {
    console.log('start')
    let options = trackerGroup.PlotOptions
    options.cevents = props.eventTable;
    let annotations = []
    for (const event of props.eventTable) {
      if (event && event.start_date) {
        const eventDate = dayjs(new Date(event.start_date));
        if (
            eventDate >= dayjs(new Date(trackerGroup.PlotData.labels[0])) &&
            eventDate <= dayjs(new Date(trackerGroup.PlotData.labels.slice(-1)[0]))) {
          const dateOnChart = dayjs(getClosestDateOnChart(eventDate,trackerGroup)).format(
              "M/DD/YY h:mm a"
          );
          const annotationImg = getImage(event.name);
          if (annotationImg) {
            annotationImg.onload = () => {
              trackerGroup.PlotOptions.plugins.annotation.annotations.forEach((item) => {
                if (annotationImg.eventType === item.eventType) {
                  item.label.content = annotationImg
                }
              })
            }
          }
          annotations.push({
            type: "line",
            id: "line" + annotationId + '_' + event.name,
            drawTime: "afterDatasetsDraw",
            mode: "vertical",
            scaleID: "x_axis_0",
            value: dateOnChart,
            borderWidth: 5,
            borderColor: getAnnotationColor(event.event_type),
            eventType: event.name,
            label: {
              position: "center",
              backgroundColor: 'white',
              content: "",
              width: '30%',
              height: '30%',
              display: true,
              enabled: true,
            },
            onMouseenter(e) {
              console.log("mouseenter");
              this.options.helper(this.options.value, this.options.eventType);
              console.log("Running...");
            },

            onMouseleave(e) {
              console.log("mouseleave");
              this.options.helper2(this.options.value, this.options.eventType);
              console.log("Running...");
            },

            helper: (date, type) => {
              console.log(options.plugins.annotation.annotations);
              console.log(date);
              for (const line of options.plugins.annotation.annotations) {
                console.log(line);

                if (line.value === date) {
                  line.label.enabled = true;
                  line.borderColor = this.getAnnotationColor(type + "_bold");
                  //!line.label.enabled;
                }
              }

            },

            helper2: (date, type) => {
              console.log(options.plugins.annotation.annotations);
              console.log(date);
              for (const line of options.plugins.annotation.annotations) {
                console.log(line);

                if (line.value === date) {
                  line.label.enabled = false;
                  line.borderColor = this.getAnnotationColor(type);
                  //!line.label.enabled;
                  console.log(line);
                }
              }
            },
          });
        }
      }
    }
    options.plugins.annotation.annotations = annotations
  })
}
defineExpose({
    initializeTrackerGroups,
});
function getAnnotationColor(name) {
  let color = "rgba(193, 120, 245, 0.6)";
  switch (name) {
    case "Rain":
      color = "rgba(117, 223, 255, 0.6)";
      break;
    case "Treatment":
      color = "rgba(193, 120, 245, 0.6)";
      break;

    case "Rain_bold":
      color = "rgba(117, 223, 255, 1)";
      break;

    case "Treatment_bold":
      color = "rgba(193, 120, 245, 1)";
      break;
    default:
      break;
  }
  return color;
}
function getClosestDateOnChart(eventDate,trackerGroup) {
  const arr = trackerGroup.PlotData.labels.map((x) => new Date(x))
  arr.sort((a, b) => {
    var distanceA = Math.abs(a - eventDate);
    var distanceB = Math.abs(b - eventDate);
    return distanceA - distanceB;
  });
  return arr[0];
}
</script>

<style scoped>
.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>