export function calibrateHistory(valueHistory, timeHistory, calibrationMoments) {
    if (calibrationMoments.length === 0) {
        return valueHistory; // No need to calibrate
    }

    // Step #1. Sort calibration moments
    const sortedMoments = [...calibrationMoments];
    sortedMoments.sort(function(left, right) {
        const leftTimeStamp = new Date(left.activated_at);
        const rightTimeStamp = new Date(right.activated_at);
        return (leftTimeStamp < rightTimeStamp) ? -1 : 1;
    }); 

    let smallestMoment = sortedMoments[0];

    // Step #2. Scale the values based on calibration settings
    const result = [];
    for(let index = 0; index < valueHistory.length; index++) {
        const rawValue = valueHistory[index];
        const timeStamp = new Date(timeHistory[index]);
        let candidateMoment = null;

        sortedMoments.every((calibration)=> {
            const calibrationTimestamp = new Date(calibration.activated_at);
            if(calibrationTimestamp < timeStamp) // Good
            {
                candidateMoment = calibration;
                return true;
            }
            else {
                return false;
            }
        })

        if (candidateMoment === null) {
            candidateMoment = smallestMoment;
        }

        let ratio = 1;
        if (candidateMoment.ugL !== null && candidateMoment.rfu !== null && candidateMoment.rfu !== 0) {
            ratio = candidateMoment.ugL / candidateMoment.rfu;
        }

        const finalValue = rawValue * ratio;
        result.push(finalValue);
    }

   return result;
}