<template>
  <div class="home">
    <Header :is-back="true"></Header>
    <b-overlay :show="show" rounded="sm" style="width: 100%;height: 100%;" opacity="0.50">
      <b-tabs content-class="mt-3">
        <b-tab title="Chart Events" active>
          <div>
            <!-- Table -->
            <b-table
                id="my-table-1"
                :fields="data.chart.field"
                :items="data.chart.property"
                :current-page="data.chart.currentPage"
                :per-page="perPage"
                :bordered="true"
                :fixed="true"
                :no-border-collapse="true"
                striped
                hover
                show-empty
                empty-text="No Chart Events Data"
            >
            </b-table>

            <!-- Pagination -->
            <b-pagination
                v-model="data.chart.currentPage"
                :total-rows="data.chart.totalItems"
                :per-page="perPage"
                aria-controls="my-table-1"
                align="center"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab title="Calibration Events">
          <div>
            <!-- Table -->
            <b-table
                id="my-table-2"
                :fields="data.calibration.field"
                :items="data.calibration.property"
                :current-page="data.calibration.currentPage"
                :per-page="perPage"
                :bordered="true"
                :fixed="true"
                :no-border-collapse="true"
                striped
                hover
                show-empty
                empty-text="No Calibration Events Data"
            >
              <template #cell(description)="data">
                <b class="cell-normal">{{data.value.sensorName}}</b>
                <b v-if="data.value.rfu" class="cell-normal">:&nbsp;{{data.value.rfu}}&nbsp;RFU</b>
                <b v-if="data.value.rfu" class="cell-normal">&nbsp;↔︎&nbsp;</b>
                <b v-if="data.value.ugl" class="cell-normal">{{data.value.ugl}}&nbsp;ug/l</b>
              </template>
            </b-table>

            <!-- Pagination -->
            <b-pagination
                v-model="data.calibration.currentPage"
                :total-rows="data.calibration.totalItems"
                :per-page="perPage"
                aria-controls="my-table-2"
                align="center"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab title="Sensor Alert Events">
          <div>
            <b-table
                id="my-table-3"
                :fields="data.sensor.field"
                :items="data.sensor.property"
                :current-page="data.sensor.currentPage"
                :per-page="perPage"
                :bordered="true"
                :fixed="true"
                :no-border-collapse="true"
                striped
                hover
                show-empty
                empty-text="No Sensor Alert Events Data"
            >
            </b-table>
            <!-- Pagination -->
            <b-pagination
                v-model="data.sensor.currentPage"
                :total-rows="data.sensor.totalItems"
                :per-page="perPage"
                aria-controls="my-table-3"
                align="center"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab title="Device Health Alerts">
          <div>
            <b-table
                id="my-table-4"
                :fields="data.device.field"
                :items="data.device.property"
                :current-page="data.device.currentPage"
                :per-page="perPage"
                :bordered="true"
                :fixed="true"
                :no-border-collapse="true"
                striped
                hover
                show-empty
                empty-text="No Device Health Alerts Data"
            >
            </b-table>
            <!-- Pagination -->
            <b-pagination
                v-model="data.device.currentPage"
                :total-rows="data.device.totalItems"
                :per-page="perPage"
                aria-controls="my-table-4"
                align="center"
            ></b-pagination>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script setup lang="ts">
import Header from "../components/Header.vue";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import dayjs from "dayjs";
import {useAuthStore} from "../stores/authStore.ts";
import {deviceRoot} from "../vue-client.ts";

let perPage = ref(50)
let slotId = useRoute().params.id
let show = ref(true)

let data = ref({
  chart:{
    field:[
      { key: 'date', label: 'Date',sortable:true},
      { key: 'time', label: 'Time' ,sortable:true},
      { key: 'event', label: 'Type of Event',sortable:false },
      { key: 'description', label:'Notes（for Custom Events）',sortable:false }
    ],
    currentPage:1,
    property:[],
    totalItems:0
  },
  calibration:{
    field:[
      { key: 'date', label: 'Date',sortable:true},
      { key: 'time', label: 'Time' ,sortable:true},
      { key: 'event', label: 'Type of Event',sortable:false },
      { key: 'description', label:'Parameters calibrate',sortable:false }
    ],
    currentPage:1,
    property:[],
    totalItems:0
  },
  sensor:{
    field:[
      { key: 'date', label: 'Date',sortable:true},
      { key: 'time', label: 'Time' ,sortable:true},
      { key: 'event', label: 'Sensor Type',sortable:false },
      { key: 'threshold', label:'Threshold Value',sortable:true },
      { key: 'value', label:'Sensor Value',sortable:true },
      { key: 'units', label:'Units',sortable:false },
    ],
    currentPage:1,
    property:[],
    totalItems:0
  },
  device:{
    field:[
      { key: 'date', label: 'Date',sortable:true},
      { key: 'time', label: 'Time' ,sortable:true},
      { key: 'event', label: 'Alert Type',sortable:false },
      { key: 'threshold', label:'Alert Threshold',sortable:true },
      { key: 'value', label:'Alert Value',sortable:true },
    ],
    currentPage:1,
    property:[],
    totalItems:0
  }
})

onMounted(async () => {
  console.log('onMounted')
  await getEventsGlobal()
  await getFieldData()
  await getAlertsData()
  show.value = false
})

/**
 * 查询events 事件列表
 */
async function getEventsGlobal() {
  // get custom events
  const eventTable = await getEvent(slotId);
  const timeZone = dayjs.tz.guess();
  eventTable.forEach((item,index) => {
    data.value.chart.property.push({
      date:dayjs.utc(item.start_date).tz(timeZone).format("M/DD/YYYY"),
      time:dayjs.utc(item.start_date).tz(timeZone).format("h:mm A"),
      event:item.name.toUpperCase(),
      description: item.description || '',
      timeStamp:dayjs.utc(item.start_date).tz(timeZone).valueOf()
    })
  })
  data.value.chart.property.sort((a,b) => {
    return b.timeStamp - a.timeStamp
  })
  data.value.chart.totalItems = data.value.chart.property.length
}

async function getFieldData(){
  const fieldData = await getFieldCals(slotId)
  const standardFieldData = await getStandardField(slotId)
  let result = []
  const timeZone = dayjs.tz.guess();
  fieldData.forEach((item,index) => {
    let sensorName = item.sensorName
    let moments = item.moments
    if (moments.length > 0) {
      for(let i = 0;i <moments.length;i++) {
        if (moments[i].rfu) {
          result.push({
          date:dayjs.utc(moments[i].activated_at).tz(timeZone).format("M/DD/YYYY"),
          time:dayjs.utc(moments[i].activated_at).tz(timeZone).format("h:mm A"),
          event:'FIELD CAL',
          description:{
            sensorName,
            rfu:moments[i].rfu.toFixed(2),
            ugl:moments[i].ugL
          },
          timeStamp:dayjs.utc(moments[i].activated_at).tz(timeZone).valueOf()
        })
        }   
      }
    } else {
      result.push({
        date:dayjs.utc(item.updatedAt).tz(timeZone).format("M/DD/YYYY"),
        time:dayjs.utc(item.updatedAt).tz(timeZone).format("h:mm A"),
        event:'FIELD CAL',
        description:{
          sensorName
        },
        timeStamp:dayjs.utc(item.updatedAt).tz(timeZone).valueOf()
      })
    }
  })
  standardFieldData.forEach((item,index) => {
    let feature = item.feature
    let desc = ''
    if (feature === 0) {
      desc = '[Turbidity, Phycocyanin, Chlorophyll]'
    }else if (feature === 2) {
      desc = 'DO'
    }else if (feature === 3) {
      desc = 'pH'
    }
    result.push({
      date:dayjs.utc(item.updatedAt).tz(timeZone).format('M/DD/YYYY'),
      time:dayjs.utc(item.updatedAt).tz(timeZone).format("h:mm A"),
      event:'STANDARD  CAL',
      description:{
        sensorName:desc
      },
      timeStamp:dayjs.utc(item.updatedAt).tz(timeZone).valueOf()
    })
  })
  result.sort((a,b) => {
    return b.timeStamp - a.timeStamp
  })
  data.value.calibration.property = result
  data.value.calibration.totalItems = result.length
}

const devicesName = ['battVolts','battSOC','sigStrength','sigQuality','panelVolts','leakDetect','brushError','uartError']
async function getAlertsData(){
  let alertData = await getAllAlerts(slotId)
  let devices = []
  let sensors = []
  const timeZone = dayjs.tz.guess();
  alertData.forEach((item,index) => {
    let pushItem = {
      date:dayjs.utc(item.updatedAt).tz(timeZone).format('M/DD/YYYY'),
      time:dayjs.utc(item.updatedAt).tz(timeZone).format("h:mm A"),
      event:item.sensorName,
      threshold:item.threshold,
      value:item.sensorValue,
      units:item.units || '',
      timeStamp:dayjs.utc(item.updatedAt).tz(timeZone).valueOf()
    }
    if (item.sensorName && devicesName.some(ele => ele === item.sensorName)) {
      delete pushItem.units
      devices.push(pushItem)
    }else {
      sensors.push(pushItem)
    }
  })
  devices.sort((a,b) => {
    return b.timeStamp - a.timeStamp
  })
  sensors.sort((a,b) => {
    return b.timeStamp - a.timeStamp
  })
  data.value.sensor.property = sensors
  data.value.sensor.totalItems = sensors.length
  data.value.device.property = devices
  data.value.device.totalItems = devices.length
}

///////////////------request
async function getEvent(slotId) {
  const authStore = useAuthStore();
  let authString = "Bearer " + authStore.authUser.authToken;
  const response = await fetch(`${deviceRoot}/slots/${slotId}/events`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    },
  });
  const events = await response.json();
  return events;
}
async function getFieldCals(slotId) {
  const authStore = useAuthStore();
  let authString = "Bearer " + authStore.authUser.authToken;
  const response = await fetch(`${deviceRoot}/slots/${slotId}/fieldcal`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    },
  });
  return await response.json();
}
async function getStandardField(slotId){
  const authStore = useAuthStore();
  let authString = "Bearer " + authStore.authUser.authToken;
  const response = await fetch(`${deviceRoot}/functions/calibrations/${slotId}`,{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    }
  })
  return response.json()
}
async function getAllAlerts(slotId){
  const authStore = useAuthStore();
  let authString = "Bearer " + authStore.authUser.authToken;
  const response = await fetch(`${deviceRoot}/alerts/${slotId}`,{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authString,
    }
  })
  return response.json()
}
</script>

<style lang="scss">
.nav-tabs .nav-link{
  color: white !important;
  border: 1px solid #ffffff;
}

.nav-tabs .nav-link.active {
  color: #007bff !important;
}
.cell-normal{
  font-weight: normal;
}
</style>
<style scoped lang="scss">

</style>