<template>
    <div class="home">
        <Header></Header>
        <div class="front page">
            <div class="front page">
                <div>
                    <!-- on load -->
                    <loading :active="isStartLoading" :is-full-page="fullPage" :can-cancel="false" :loader="loaderType"
                        :background-color="loaderBgnd" :opacity="loaderOpacity" :height="loaderHeight" :width="loaderWidth"
                        :color="loaderColor" style="text-align:center; position: absolute; top: 10%;">
                        <template v-slot:before>
                            <h2 style="color:#ffffff">Please wait while your dashboard is prepared.</h2>
                            <h2 style="color:#ffffff">Double click or press ESC twice to bypass.</h2>
                        </template>
                    </loading>
                </div>
                <div>
                    <div class="card">
                        <GMapMap style="width: 100%; height: 400px;" :zoom="initDashboardMap" :center="{
                            lat: parseFloat(latGlobal),
                            lng: parseFloat(longGlobal),
                        }" ref="map">
                            <div v-for="(dat, index) in outDat" :key="index">
                                <GMapMarker :position="dat.latLong" v-if="checkOnline(dat)"
                                :clickable="true" @click="openWindow(dat)"  :title="dat.name" @mouseover="hoverWindow(dat)" @mouseout="hover = false">
                                </GMapMarker>
                                <GMapInfoWindow :closeclick = "true" @closeclick="window_open = false" :opened="window_open" :position="infoWindow"
                                    :options="{
                                        pixelOffset: { width: 0, height: -35 },
                                    }">
                                    <div style="font-size:14px;margin-bottom:10px;">
                                        {{ gmapName }}
                                    </div>
                                    <b-button style="max-height:35px;" size="sm" @click="(e) => _setDeviceId(gmapId)"
                                        :value="gmapId">
                                        Details
                                    </b-button>
                                </GMapInfoWindow>
                                <GMapInfoWindow :opened="hover" :position="infoWindow2"
                                    :options="{
                                        pixelOffset: { width: 0, height: -35 },
                                    }">
                                    <div v-if="!isNull" style="font-size:14px;margin-bottom:10px;">
                                        <u>{{ gmapName }}</u><br>
                                        Phycocyanin: {{ gmapPhy }} RFU<br>
                                        Chlorophyll: {{ gmapChl }} RFU <br>
                                    </div>
                                    <div v-else style="font-size:14px;margin-bottom:10px;">
                                        <u>{{ gmapName }}</u><br>
                                        Device Offline
                                    </div>
                                </GMapInfoWindow>     
                            </div >                    
                        </GMapMap>
                    </div>

                    <b-row v-if="isArt" style="justify-content: center;">
                        <b-btn style="background-color: #064ea1; border: 2px solid  #cecece; font-size: 0.8rem;"
                            @click="addDeviceModal = !addDeviceModal">
                            Add Device
                        </b-btn>
                    </b-row>
                    <b-row>
                        <b-card-group class="col-md-4 col-sm-6 col-xl-3" style="padding:1px" v-for="(row, index) in outDat"
                            :key="index">
                            <!-- quick start -->
                            <b-card v-if="quickLoadStart" no-body class="btn btn-dark" style="padding:1px;">
                                <div v-if="row.online" class="card-header blink-bg blink-green"
                                    style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <div v-else class="card-header devOffline" style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <div class="card-body" style="padding: 2px !important;">
                                    <b-row class="statusRow" v-if="row.account_name">
                                        <p class="statusText">{{ row.account_name }} (Version: {{ row.version }})}}</p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">{{ getFeatureName(row) }}</p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">
                                            Device Last Heard:
                                        </p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">
                                            {{ row.lastHeard }}
                                        </p>
                                    </b-row>
                                </div>
                                <div class="card-footer" style="padding: 2px !important;">
                                    <b-btn @click="(e) => _setDeviceId(row.id)" :value="row.id">Details</b-btn>
                                </div>
                            </b-card>
                            <!-- Device Offline -->
                            <b-card v-else-if="!row.online" no-body class="btn btn-dark" style="padding:1px;">
                                <div class="card-header devOffline" style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <div class="card-body" style="padding: 2px !important;">
                                    <b-row class="statusRow">
                                        <p class="statusText">
                                            {{ row.account_name }}
                                        </p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">
                                            {{ getDeviceStatus(row) }}
                                        </p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">Last Heard: {{ row.lastHeard }}</p>
                                    </b-row>
                                </div>
                                <div class="card-footer" style="padding: 2px !important;">
                                    <b-btn @click="(e) => _setDeviceId(row.id)" :value="row.id">Details</b-btn>
                                </div>
                            </b-card>
                            <!-- full data cards -->
                            <b-card v-else no-body class="btn btn-dark" style="padding:1px;">
                                <!-- Yellow Flag Set -->
                                <div v-if="row.online && row.flag == 1" class="card-header blink-bg blink-yellow"
                                    style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <!-- Red flag set -->
                                <div v-else-if="row.online && row.flag == 2" class="card-header blink-bg blink-red"
                                    style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <!-- default -->
                                <div v-else class="card-header statusBgd" style="width:100%; padding: 2px !important;">
                                    {{ row.name }}
                                    <slot-actions :slot="row"></slot-actions>
                                </div>
                                <div class="card-body" style="padding: 0 !important;">
                                    <b-row class="statusRow" v-if="row.account_name">
                                        <p class="statusText">{{ row.account_name }} (Version: {{ row.version }})</p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">{{ getFeatureName(row) }}</p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <p class="statusText">
                                            {{ row.lastHeard }}
                                        </p>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <b-col>
                                            <p class="statusText">
                                                Battery
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="row.bat >= 70" icon="battery-full" variant="success">
                                            </BootstrapIcon>
                                            <BootstrapIcon v-else-if="row.bat < 80 && row.bat >= 60" icon="battery-full"
                                                variant="warning">
                                            </BootstrapIcon>
                                            <BootstrapIcon v-else icon="battery" variant="danger"></BootstrapIcon>
                                            <span class="statusText">{{ row.bat }} % </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow" v-if="row.feature === 2">
                                        <b-col>
                                            <p class="statusText">
                                                DO
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <span class="statusText">{{ row.lastDO }} mg/L </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow" v-if="row.feature === 3">
                                        <b-col>
                                            <p class="statusText">
                                                pH
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <span class="statusText">{{ row.lastPH }}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow" v-if="featureEnabled(row, 'phycocyanin')">
                                        <b-col>
                                            <p class="statusText">
                                                Phycocyanin
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="row.lastPC >= row.pcAlert" icon="x" variant="danger">
                                            </BootstrapIcon>
                                            <BootstrapIcon
                                                v-else-if="row.lastPC < row.pcAlert && row.lastPC >= 0.8 * row.pcAlert"
                                                icon="x" variant="warning"></BootstrapIcon>
                                            <BootstrapIcon v-else icon="check" variant="success"></BootstrapIcon>
                                            <span class="statusText">{{ row.lastPC }} RFU </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow" v-if="featureEnabled(row, 'chlorA')">
                                        <b-col>
                                            <p class="statusText">
                                                Chlorophyll
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="row.lastCA >= row.caAlert" icon="x" variant="danger">
                                            </BootstrapIcon>
                                            <BootstrapIcon
                                                v-else-if="row.lastCA < row.caAlert && row.lastCA >= 0.8 * row.caAlert"
                                                icon="x" variant="warning"></BootstrapIcon>
                                            <BootstrapIcon v-else icon="check" variant="success"></BootstrapIcon>
                                            <span class="statusText">{{ row.lastCA }} RFU </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <b-col>
                                            <p class="statusText">
                                                Turbidity
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="row.lastTB >= row.tbAlert" icon="x" variant="danger">
                                            </BootstrapIcon>
                                            <BootstrapIcon
                                                v-else-if="row.lastTB < row.tbAlert && row.lastTB >= 0.8 * row.tbAlert"
                                                icon="x" variant="warning"></BootstrapIcon>
                                            <BootstrapIcon v-else icon="check" variant="success"></BootstrapIcon>
                                            <span class="statusText">{{ row.lastTB }} NTU </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <b-col>
                                            <p class="statusText">
                                                Water Temp
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            
                                            <span v-if='unitF' class="statusText">{{ row.lastTEMP }} &deg; C </span>
                                            <span v-else class="statusText">{{ Math.round((row.lastTEMP * 1.8 + 32)) }} &deg; F </span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <b-col>
                                            <p class="statusText">
                                                In Water
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="row.inWater" icon="check" variant="success">
                                            </BootstrapIcon>
                                            <BootstrapIcon v-else icon="x" variant="danger"></BootstrapIcon>
                                        </b-col>
                                    </b-row>
                                    <b-row class="statusRow">
                                        <b-col>
                                            <p class="statusText">
                                                FixOK
                                            </p>
                                        </b-col>
                                        <b-col style="padding-left: 3px;">
                                            <BootstrapIcon v-if="true" icon="check" variant="success">
                                            </BootstrapIcon>
                                            <BootstrapIcon v-else icon="x" variant="danger"></BootstrapIcon>
                                        </b-col>
                                    </b-row>
                                </div>
                                
                                <div class="card-footer" style="padding: 0 !important;">
                                    <b-btn @click="(e) => _setDeviceId(row.id)" :value="row.id">Details</b-btn>
                                    
                                </div>
                            </b-card>
                        </b-card-group>
                    </b-row>
                </div>
                <div style="margin:10px"></div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import router from "../router";
import Header from "../components/Header.vue";
import { useSlotStore } from "../stores/slotStore";
import { useAuthStore } from "../stores/authStore";
import { ref, computed } from "vue";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import SlotActions from "../components/SlotActions.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import { fromSlotsToSummaries, featureEnabled } from "../utilities/slotUtilities";
import { storeToRefs } from "pinia";
import { usePreferenceStore } from "../stores/preferenceStore";
import {useDurationStore} from "../stores/durationStore.ts";


dayjs.extend(utc);
dayjs.extend(tz);

const isStartLoading = ref(false);
const fullPage = ref(false);
const quickLoadStart = ref(false);
const outDat = ref([]);
const loaderType = ref("spinner");
const loaderBgnd = ref("rgb(50, 59, 77)");
const loaderOpacity = ref(0.75);
const loaderHeight = ref(60);
const loaderWidth = ref(60);
const loaderColor = ref("#ffffff");
const initDashboardMap = ref(5);
const latGlobal = ref(40.017377);
const longGlobal = ref(-105.258466);
const infoWindow = ref({ lat: 0, lng: 0 });
const infoWindow2 = ref({ lat: 0, lng: 0 });
const gmapName = ref("");
const gmapPhy = ref(0)
const gmapChl = ref(0)
const isNull = ref(false);
const gmapId = ref("");
const window_open = ref(false);
const addDeviceModal = ref(false);
const authStore = useAuthStore();
const unitF = ref(false)
const hover = ref(false);

const getDeviceStatus = (row) => {
    if (authStore.isArt) {
        if (row.enabled === false && row.online === false) {
            return "Device Offline.";
        }
        else if (row.enabled === false && row.online === true) {
            return "";
        }
        else if (row.enabled === true && row.online === true) {
            return "";
        }
        else if (row.enabled === true && row.online === false) {
            return "Device Offline.";
        }
    }
    else {
        if (row.enabled === false) {
            return "Subscription Expired: Please contact sales@aquarealtime.com";
        }
        else if (row.enabled === true && row.online === true) {
            return "";
        }
        else if (row.enabled === true && row.online === false) {
            return "Device Offline.";
        }
    }
}

const isArt = computed(() => {
    return authStore.isArt;
});

const getFeatureName = (row: any) => {
    if (row.feature === 1) {
        return "Turbidity Tracker";    
    }
    else if (row.feature === 2) {
        return "Multi Tracker-DO";
    }
    else if (row.feature === 3) {
        return "Multi Tracker-PH";
    }
    else if (row.feature === 4) {
        return "Multi Tracker-PS";
    }

    return "Algae Tracker";
}

const openWindow = (dat) => {
    infoWindow.value = { lat: parseFloat(dat.latLong.lat), lng: parseFloat(dat.latLong.lng) };
    gmapName.value = dat.name;
    gmapId.value = dat.id;
    window_open.value = true;
    hover.value = false;
};
const hoverWindow = (dat) => {
    infoWindow2.value = { lat: parseFloat(dat.latLong.lat), lng: parseFloat(dat.latLong.lng) };
    gmapName.value = dat.name;
    gmapPhy.value = dat.lastPC;
    gmapChl.value = dat.lastCA;
    gmapId.value = dat.id;
    hover.value = true;
    window_open.value = false;
    if(gmapPhy.value == null){
        isNull.value = true;
    }
    else{
        isNull.value = false;
    }
};

const checkOnline = (dat) => {
    if (dat.name === 'Global Algae AT1') {
        console.log('Wow!');
    }
    return dat.online;
}

// Based on all slot summaries' lat & long info, figure out the center point and the zoom level
const updateMapBoundaries = (latTmp, longTmp) => {
    var diffLat = 0;
    var diffLong = 0;
    if (latTmp.length >= 1 && longTmp.length >= 1) {
        latGlobal.value = latTmp.reduce((prev, curr) => prev + curr) / latTmp.length;
        longGlobal.value = longTmp.reduce((prev, curr) => prev + curr) / longTmp.length;
        diffLat = Math.max(...latTmp) - Math.min(...latTmp);
        diffLong = Math.max(...longTmp) - Math.min(...longTmp);
    } else {
        latGlobal.value = 40.017377;
        longGlobal.value = -105.258466;
    }
    let initDashboardMap = 0;
    if (diffLat <= 0.2 && diffLong <= 0.2) {
        initDashboardMap = 15;
    } else if (diffLat <= 1 && diffLong <= 1) {
        initDashboardMap = 10;
    } else if (diffLat <= 2 && diffLong <= 2) {
        initDashboardMap = 8;
    } else if (diffLat <= 3 && diffLong <= 3) {
        initDashboardMap = 6;
    } else {
        initDashboardMap = 4;
    }
    return initDashboardMap;
};

const initDat = async () => {
    const slotStore = useSlotStore();
    isStartLoading.value = true;
    var allSlots = [];
    try {
        await Promise.all([
            slotStore.fetchAccountSlots(),
            slotStore.fetchAccountUsers()
        ]);
        allSlots = slotStore.slots;
    } catch (err) {
        console.log(err);
    }
    let latTmp = [];
    let longTmp = [];
    outDat.value = await fromSlotsToSummaries(isArt.value, allSlots, latTmp, longTmp);

    initDashboardMap.value = updateMapBoundaries(latTmp, longTmp);
    
    const preferenceStore = usePreferenceStore();
    await preferenceStore.fetchPreference();
    const { preference } = storeToRefs(preferenceStore);
    unitF.value = preference.value.options.unitF;

    isStartLoading.value = false;
};

const _setDeviceId = async (id) => {
    // If the device is disabled, we need to alert the user
    const slotStore = useSlotStore();
    const { slots } = storeToRefs(slotStore);
    const slot = slots.value.find((candidate) => candidate._id === id);
    if (slot === undefined || (!isArt.value && slot.enabled === false)) {
        alert("This device has been de-activated for this account for administrative reasons, such as non-payment of subscription. Please contact AquaRealTime to re-activate this device.");
    }
    else {
        router.push({ name: `slot`, params: { id }});
    }
};

/////////////////////
// Actual initialization
if (!authStore.authUser) {
    router.push({ name: "signIn" });
}
else {
    await initDat();
}
/////////////////////
</script>

<style lang="scss">
// Extra small screen / phone
$screen-xs: 425px !default;
// Small screen / tablet
$screen-sm: 768px !default;
// Medium screen / desktop
$screen-md: 992px !default;
// Large screen / wide desktop
$screen-lg: 1560px !default;

:root {
    --headerTitle: #ffffff;
    --subTitle: #d7d7d8;
    --backgroundMain: #2c3e50;
    --cardBorder: #005fbe;
    --cardBackground: #282d38;
}

.home {
    display: block;
    margin: auto;
    background-color: rgb(40, 45, 56);
}

.row {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.defaultRow {
    margin: 0 3% 0 3%;
}

.stickyTop {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    top: 0;
}

.overlay {
    width: 100%;
    z-index: 9;
    margin: 0;
    background-color: var(--headerTitle);
    opacity: 0.7;
    color: var(--headerTitle);
}

.statusBgd {
    background-color: #28a745;
}

.statusRow {
    margin-bottom: 0;
    justify-content: center;
}

.devOffline {
    background-color: #dee8fd40;
}

.blink-bg {
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
}

.blink-yellow {
    // animation: blinkYellow 4s infinite;
    background-color: #ddce00;
}

.blink-red {
    // animation: blinkRed 4s infinite;
    background-color: #800101;
}

.blink-green {
    // animation: blinkGreen 4s infinite;
    background-color: #018001;
}

@keyframes blinkGreen {
    0% {
        background-color: #018001;
    }

    60% {
        background-color: #018001e5;
    }

    70% {
        background-color: #018001c5;
    }

    80% {
        background-color: #01800173;
    }

    90% {
        background-color: #01800141;
    }

    100% {
        background-color: #01800121;
    }
}

@keyframes blinkRed {
    0% {
        background-color: #800101;
    }

    70% {
        background-color: #800101c5;
    }

    80% {
        background-color: #80010165;
    }

    90% {
        background-color: #80010128;
    }

    100% {
        background-color: #80010100;
    }
}

@keyframes blinkYellow {
    0% {
        background-color: #ddce00;
    }

    70% {
        background-color: #ddce00c9;
    }

    80% {
        background-color: #ddce0088;
    }

    90% {
        background-color: #ddce0054;
    }

    100% {
        background-color: #ddce0000;
    }
}

.statusLabel {
    color: #d7d7d8;
    margin: 0;
}

.statusVal {
    font-size: 1rem;
    margin-left: 2%;
    color: #d7d7d8;
}

.statusText {
    margin-bottom: 2px;
}

.cust-col {
    padding: 0;
}

.editUnit {
    min-width: 150px;
    margin: 0 0 5px 0;
}

.unitText {
    display: inline-block;
    color: black;
    padding: 5px;
    margin-bottom: 0;
}

.paramText {
    color: black;
    margin: auto;
}

.deg-switch {
    display: inline-block;
    color: rgb(6, 78, 161);
    padding: 0 0 0 8px;
    margin-left: 5px;
}

.util-tools {
    color: #d7d7d8;
    height: 30px;
    width: 30px;
    margin: 5px;
}

.bi.util-tools {
    width: 30px;
    height: 30px;
}

.util-opts {
    display: flex;
    float: right;
}

.form-check-label {
    color: #d7d7d8;
    margin-left: 5px;
}

.util-text {
    color: #d7d7d8;
    display: flex;
    float: left;
    margin: 5px auto 5px 5px;
}

.playRow {
    width: 100%;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
}

.playgroundHeader {
    text-align: center;
    font-family: sans-serif;
    margin-bottom: 0;
    margin-left: 10px;
    padding-left: 10px;
    color: #ffffff;
}

.playgroundText {
    color: #d7d7d8;
    display: flex;
    // padding: 0;
    margin-bottom: 3px;
}

.playgroundInput {
    max-width: 100px;
    min-width: 60px;
    max-height: 30px;
}

.playgroundCard {
    display: block;
    border-top: 3px solid rgb(42, 59, 94);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    margin: 5px;
    padding: 5px;
    // min-height: 74px;
    // max-height: 300px;
    background-color: #313846;
}

.playgroundChart {
    height: 450px;
    width: 100%;
    position: relative;
}

.exprt-form {
    display: block;
    min-height: 50px;
    min-width: 200px;
    max-width: 400px;
    margin-left: 10px;
}

.util-sub-head {
    display: flex;
    color: #d7d7d8;
    font-size: 14px;
}

.util-p {
    display: block;
}

.btn {
    background-color: rgb(50, 59, 77);
    border: none;
}

.img {
    min-width: 70px;
}

.deviceActiveImg {
    width: 40px;
}

#batt {
    margin-left: 20px;
}

#battPercent {
    :hover {
        display: show;
    }
}

#mapBox {
    display: block;
    height: 94%;
    min-height: 250px;

    #device {
        display: block;
        float: left;
        color: white;
        margin-left: 5px;
    }

    #gps {
        display: block;
        float: left;
        clear: both;
        width: 100%;
        margin: auto;
        color: white;
        font-size: 16px;
        height: min-content;

        span {
            display: block;
            float: right;
            clear: none;
            width: 100%;
            color: #d7d7d8;
            text-align: center;
        }
    }
}

#weatherBox {
    display: block;
    min-height: 250px;

    .Weather {
        display: block;
    }
}

#doughnut {
    padding: 0;
}

.lineChart {
    height: 300px;
    width: 100%;
    position: relative;
}

.card {
    display: block;
    border-top: 3px solid rgb(42, 59, 94);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    margin: 0px;
    padding: 5px;
    // min-height: 74px;
    // max-height: 300px;
    border-top: solid 2px rgb(6, 78, 161);
    background-color: rgb(50, 59, 77);

    &.doughnut-chart {
        overflow: hidden;
    }

    .device-title {
        font-size: 14px;
        color: #d7d7d8;
        padding: 5px;
        text-align: center;

        .text {
            color: black;
        }
    }

    .title {
        display: block;
        float: left;
        margin-bottom: 6%;
        font-size: 14px;
        color: #d7d7d8;

        .text {
            color: black;
        }
    }
}

.chart-value {
    text-align: center;
    font-size: 15px;
    color: white;
    max-width: 100px;
    line-height: 22px;
    margin: auto;
    margin-top: -40px;
    // margin-bottom: 40px;
}

.battStatImg {
    max-width: 60px;
    margin-left: 20%;
    margin-right: 10%;
    // margin-top: 5px;
}

.alert-icon {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    font-size: 20px;
    color: #9698a2;
}

.btn-group {
    display: flex;

    .btn-content {
        flex: 1;
    }
}

.card .dropdown-menu.show {
    --bs-dropdown-bg: rgba(255, 0, 0, 0.0);
}

#dropdown-1 {
    display: flex;
    flex-wrap: wrap;
    color: white;
    min-width: 200px;

    ul {
        background-color: rgba(40, 45, 56, 0);
        border: solid 1px rgba(40, 45, 56, 0);
        border-radius: 5px;
        width: 100%;
        margin-left: -10px;

        li {
            li:first-child {
                margin-top: -30px;
            }

            border: solid 2px rgb(40, 45, 56);
            border-radius: 5px;
            color: white;
            text-align: left;

            img {
                margin-bottom: 5px;
                padding-right: 20px;
                margin-left: -30px;
            }
        }
    }
}

#dropdown-start {
    display: flex;
    // max-width: 600px;
    width: 300px;
    // width: 30%;
    margin: auto;
    margin-top: 70px;
    padding-bottom: 350px;

    ul {
        background-color: rgb(40, 45, 56);
        border: rgb(40, 45, 56);
        width: 100%;

        li {
            li:first-child {
                margin-top: -10px;
            }

            color: white;
            font-size: 1.1rem;
            text-align: left;
            margin-bottom: 7px;

            img {
                margin-bottom: 5px;
                padding-right: 20px;
            }
        }
    }
}

.dropdown-item {
    color: white;
    width: 100%;
}

.btn-dark {
    color: white;
}

.btn-dark:hover {
    background-color: white;
    color: black;
}

.sigStatImg {
    max-width: 50px;
    margin-left: 15%;
    margin-right: 10%;
}

.calendarBtn {
    .btn {
        background-color: #f3f3f5;
        border-width: 0;
    }
}

@media (max-width: ($screen-xs - 1)) {
    .title {
        font-size: 12px;
    }

    .chart-value {
        font-size: 10px;
        max-width: 60px;
        margin-top: -30px;
        line-height: inherit;
    }

    .refresh {
        margin-left: 45%;
    }
}

@media (max-width: ($screen-sm - 1)) {
    .playrow {
        width: 100%;
    }

    .col-12 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    #dropdown-menu {
        font-size: 1.2rem;
    }

    .welcome {
        display: none;
    }

    .time {
        margin-left: auto;
    }

    .defaultRow {
        margin: 5px 3% 5px 3%;
        // flex-direction: column;
        // flex-grow: 1;
    }

    #device {
        // font-size: 1.5rem;
        margin: 0;
    }

    .customChartButton {
        // font-size: 1.1rem;
        width: 60%;
        min-height: 45px;
    }

    #mapBox {
        min-height: 400px;
        margin-bottom: 1%;
    }

    #doughnut {
        // padding: 1% 2%;
        margin-bottom: 10px;
    }

    .title {
        font-size: 12px;
    }

    .chartjs-render-monitor {
        max-height: 400px;
    }
}

@media (min-width: $screen-sm) {
    .card {
        margin: 8px;

        .title {
            font-size: 14px;
            padding: 0 15px;
            text-align: center;
        }
    }
}

@media (min-width: $screen-lg) {
    #dropdown-1 {
        display: flex;
    }
}
</style>