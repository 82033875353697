<template>
    <div v-for="tracker in trackers" class="col-12 col-md-6 cust-col">
        <div class="card chart">
            <LineChart :chartdata="tracker.PlotData" :options="tracker.PlotOptions" :plugins="[LinePlugin]"
                style="height:300px;" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlotStore } from "../stores/slotStore";
import { useDurationStore } from "../stores/durationStore";
import LineChart from "./LineChart.vue";
import LinePlugin from "../plugins/LinePlugin";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import {defineExpose, ref, watch} from "vue";
import { SensorTracker } from "../models/sensorTracker";
import { storeToRefs } from "pinia";
import {
    getLineChartColor,
    getReportMinMax,
    getAxesMinMaxStep
} from "../utilities/slotUtilities";
import {TrackerGroup} from "../models/tracker-group.ts";
import { getImage } from "../utilities/annotationImages";
let annotationId = 0;

// Look-up table
class SensorSetting {
    constructor(chartName, displayName, min, max) {
        this.chartName = chartName;
        this.displayName = displayName;
        this.min = min;
        this.max = max;
    }
}

const sensorSettings = new Map();

// unifF: true for Celcius. false for Fahrenheit
function initializeSensorSettings(unitF) {
    if (unitF === true) {
        sensorSettings.set('precipitationIntensity', new SensorSetting('Precipitation', 'Precipitation (mm/hr)', 0, 10));
        sensorSettings.set('windSpeed', new SensorSetting('Wind Speed', 'Wind Speed (kph)', 0, 36));
    }
    else {
        sensorSettings.set('precipitationIntensity', new SensorSetting('Precipitation', 'Precipitation (in/hr)', 0, 0.4));
        sensorSettings.set('windSpeed', new SensorSetting('Wind Speed', 'Wind Speed (mph)', 0, 22.4));
    }
}

// unifF: true for Celcius. false for Fahrenheit
function computeWindSpeed(valueInMeterPerSecond, unitF) {
    if (unitF === true) {
        return valueInMeterPerSecond * 3.6; // Convert to kph
    }
    else {
        return valueInMeterPerSecond * 2.23694; // Convert to miles per hour
    }
}

// unifF: true for Celcius. false for Fahrenheit
function computePrecipitationIntensity(valueInMilimeterPerHour, unitF) {
    if (unitF === true) {
        return valueInMilimeterPerHour;
    }
    else {
        return valueInMilimeterPerHour * 0.0393701; // Convert to inches per hour
    }
}


dayjs.extend(utc);
dayjs.extend(tz);

const props = defineProps({
    sensorNames: { type: Array, required: true },
    unitF: { type: Boolean, required: true }, // unifF: true for Celcius. false for Fahrenheit
    eventTable:{ type: Array}
})

const emit = defineEmits(['timeSeriesDataUpdated']);

const trackers = ref([]); // SensorTracker

watch(() => props.sensorNames,
    async (newSensorNames, oldSensorNames) => {
        await initializeSlot(newSensorNames);
    }
);

watch(() => props.unitF,
    async (newUnitF, oldUnitF) => {
        await initializeSlot(props.sensorNames);
    }
);
watch(() => props.eventTable,(newValue) => {
  updateAnnotations()
},{deep:true})

const durationStore = useDurationStore();

function getSensorDisplayName(sensorName) {
    if (sensorSettings.has(sensorName)) {
        return sensorSettings.get(sensorName).displayName;
    }
    else {
        return sensorName;
    }
}

function getSensorChartName(sensorName) {
    if (sensorSettings.has(sensorName)) {
        return sensorSettings.get(sensorName).chartName;
    }
    else {
        return sensorName;
    }
}

async function initializeSlot(sensorNames) {
    /*
    const preferenceStore = usePreferenceStore();
    const { preference } = storeToRefs(preferenceStore);
    this.unitF = preference.value.options.unitF;
    */

    initializeSensorSettings(props.unitF);

    trackers.value = [];
    if (sensorNames.length > 0) {
        const slotId = getSlotId();
        const slotStore = useSlotStore();
        const deviceTimeZone = slotStore.deviceTimeZone;
        const startTimeUtc = dayjs.tz(durationStore.startDateTime + 48 * 3600 * 1000, deviceTimeZone.timeZoneId);
        const endTimeUtc = dayjs.tz(durationStore.endDateTime, deviceTimeZone.timeZoneId);
        const payload = {
            startDate: startTimeUtc.toISOString(),
            endDate: endTimeUtc.toISOString(),
            sensors: sensorNames
        };

        // TODO: fetch time series
        await slotStore.fetchTimeSeries(slotId, payload);
        const { timeSeriesHistory } = storeToRefs(slotStore);
        sensorNames.forEach((sensorName) => {
            const sensor = timeSeriesHistory.value.sensors.find((item) => item.sensorName === sensorName);
            // const labelsUtc = sensor.history.map((item) => item.timeStamp);
            // const labels = labelsUtc.map((item) => dayjs.tz(new Date(item), deviceTimeZone.timeZoneId).format("M/DD/YY h:mm a"));
            // const values = sensor.history.map((item) => item.val);
            // const timeStamps = sensor.history.map((item) => new Date(item.timeStamp).getTime());
            const { labelsUtc, labels, values, timeStamps } = sensor.history.reduce((acc, item) => {
              const date = new Date(item.timeStamp);
              acc.labelsUtc.push(item.timeStamp);
              acc.labels.push(dayjs.tz(date, deviceTimeZone.timeZoneId).format("M/DD/YY h:mm a"));
              acc.values.push(item.val);
              acc.timeStamps.push(date.getTime());
              return acc;
            }, { labelsUtc: [], labels: [], values: [], timeStamps: [] });
            if (sensorName === 'windSpeed') {
                // Scale it from m/s to kph
                values.forEach((item, index) => {
                    values[index] = computeWindSpeed(item, props.unitF);
                })
            }
            else if (sensorName === 'precipitationIntensity') {
                // Scale it from mm/hr to in/hr
                values.forEach((item, index) => {
                    values[index] = computePrecipitationIntensity(item, props.unitF);
                })
            }

            const sensorDiplayName = getSensorDisplayName(sensorName);
            const sensorChartName = getSensorChartName(sensorName);
            const dataset = {
                data: values,
                fill: true,
                label: sensorDiplayName,
                backgroundColor: getLineChartColor(sensorName),
                borderColor: getLineChartColor(sensorName),
                borderWidth: 1,
                yAxisID: 'y_axis_1',
                hidden: false
            };
            const sensorMinRange = sensorSettings.get(sensorName).min;
            const sensorMaxRange = sensorSettings.get(sensorName).max;
            const minMax = getReportMinMax(values, sensorMinRange, sensorMaxRange);
            const minMaxStep = getAxesMinMaxStep(minMax[0], minMax[1], 10, 0);
            const tracker = new SensorTracker(sensorChartName, labels, [dataset], minMaxStep);
            trackers.value.push(tracker);

            // 发送处理后的数据到父组件
            emit('timeSeriesDataUpdated', {
                sensorName,
                data: {
                    timeStamps,
                    values,
                    displayName: sensorDiplayName,
                    chartName: sensorChartName
                }
            });
        })
    }
}

function getSlotId() {
    var url = window.location.href;
    const parts = url.split('/');
    const slotId = parts[parts.length - 1];
    return slotId;
}

function updateAnnotations() {
  trackers.value.forEach((trackerGroup: TrackerGroup) => {
    let options = trackerGroup.PlotOptions
    options.cevents = props.eventTable;
    let annotations = []
    for (const event of props.eventTable) {
      if (event && event.start_date) {
        const eventDate = dayjs(new Date(event.start_date));
        if (
            eventDate >= dayjs(new Date(trackerGroup.PlotData.labels[0])) &&
            eventDate <= dayjs(new Date(trackerGroup.PlotData.labels.slice(-1)[0]))) {
          const dateOnChart = dayjs(getClosestDateOnChart(eventDate,trackerGroup)).format(
              "M/DD/YY h:mm a"
          );
          const annotationImg = getImage(event.name);
          if (annotationImg) {
            annotationImg.onload = () => {
              trackerGroup.PlotOptions.plugins.annotation.annotations.forEach((item) => {
                if (annotationImg.eventType === item.eventType) {
                  item.label.content = annotationImg
                }
              })
            }
          }
          annotations.push({
            type: "line",
            id: "line" + annotationId + '_' + event.name,
            drawTime: "afterDatasetsDraw",
            mode: "vertical",
            scaleID: "x_axis_0",
            value: dateOnChart,
            borderWidth: 5,
            borderColor: getAnnotationColor(event.event_type),
            eventType: event.name,
            label: {
              position: "center",
              backgroundColor: 'white',
              content: "",
              width: '30%',
              height: '30%',
              display: true,
              enabled: true,
            },
            onMouseenter(e) {
              console.log("mouseenter");
              this.options.helper(this.options.value, this.options.eventType);
              console.log("Running...");
            },

            onMouseleave(e) {
              console.log("mouseleave");
              this.options.helper2(this.options.value, this.options.eventType);
              console.log("Running...");
            },

            helper: (date, type) => {
              console.log(options.plugins.annotation.annotations);
              console.log(date);
              for (const line of options.plugins.annotation.annotations) {
                console.log(line);

                if (line.value === date) {
                  line.label.enabled = true;
                  line.borderColor = this.getAnnotationColor(type + "_bold");
                  //!line.label.enabled;
                }
              }

            },

            helper2: (date, type) => {
              console.log(options.plugins.annotation.annotations);
              console.log(date);
              for (const line of options.plugins.annotation.annotations) {
                console.log(line);

                if (line.value === date) {
                  line.label.enabled = false;
                  line.borderColor = this.getAnnotationColor(type);
                  //!line.label.enabled;
                  console.log(line);
                }
              }
            },
          });
        }
      }
    }
    options.plugins.annotation.annotations = annotations
  })
}
function getAnnotationColor(name) {
  let color = "rgba(193, 120, 245, 0.6)";
  switch (name) {
    case "Rain":
      color = "rgba(117, 223, 255, 0.6)";
      break;
    case "Treatment":
      color = "rgba(193, 120, 245, 0.6)";
      break;

    case "Rain_bold":
      color = "rgba(117, 223, 255, 1)";
      break;

    case "Treatment_bold":
      color = "rgba(193, 120, 245, 1)";
      break;
    default:
      break;
  }
  return color;
}
function getClosestDateOnChart(eventDate,trackerGroup) {
  const arr = trackerGroup.PlotData.labels.map((x) => new Date(x))
  arr.sort((a, b) => {
    var distanceA = Math.abs(a - eventDate);
    var distanceB = Math.abs(b - eventDate);
    return distanceA - distanceB;
  });
  return arr[0];
}
</script>
<style scoped>
.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>