import { defineStore } from "pinia";
import { reactive } from 'vue';
import { useAuthStore } from './authStore';
import { deviceRoot } from "../vue-client";

export const usePreferenceStore = defineStore('preference', () => {
  const preference = reactive({
    options: {
      waterTrend: false,
      unitF: false,
      turbidityTrend: true,
      showCustomChart: false,
      showPlaygroundChart: false,
      smoothPlot: false,
      fieldCalPlot: false,
      rawPlot: true,
      spikeRemovalFlag: false,
      showCA: true,
      showPC: true,

      // Custom chart options
      phycocyaninSelected: true,
      chlorASelected: false,
      waterTempSelected: false,
      phSelected: false,
      doSelected: false,
      psSelected: false,
      turbiditySelected: false,
      lightSelected: true,
      battSOCSelected: false,
      sigStrengthSelected: false,
      panelVoltsSelected: false,
      algaeRatioSelected: false,
      dayAvgPCSelected: false,
      dayAvgCASelected: false,
      dayAvgTUSelected: false,
      windSpeedSelected: false,
      precipitationIntensitySelected: false,

      phycocyaninAxis: 'left',
      chlorAAxis: 'left',
      waterTempAxis: 'left',
      phAxis: 'left',
      doAxis: 'left',
      psAxis: 'left',
      turbidityAxis: 'left',
      lightAxis: 'left',
      battSOCAxis: 'left',
      sigStrengthAxis: 'left',
      panelVoltsAxis: 'left',
      algaeRatioAxis: 'left',
      dayAvgPCAxis: 'left',
      dayAvgCAAxis: 'left',
      dayAvgTUAxis: 'left',
      windSpeedAxis: 'left',
      precipitationIntensityAxis: 'left',
      qualityControlOn:true, //exclusion switch (qualityControlOff has Deprecated)
      spikeRemovalThreshold:3
    }
  });

  const compPreference = reactive({
    options: {
    }
  });

  async function fetchPreference() {
    // Get user preferences
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchUrl = `${deviceRoot}/preferences/`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
    });

    const data = await response.json();
    Object.assign(preference.options, data);
  }

  async function fetchCompPreference() {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchUrl = `${deviceRoot}/compSettings/`;
    const response = await fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
    });

    const data = await response.json();
    Object.assign(compPreference.options, data);
  }

  async function updatePreference(val) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchUrl = `${deviceRoot}/preferences/`;
    const response = await fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(val),
    });

    const data = await response.json();
    Object.assign(preference.options, data);
  }

  async function updateCompPreference(val) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchUrl = `${deviceRoot}/compSettings/`;
    const response = await fetch(fetchUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(val),
    });

    const data = await response.json();
    Object.assign(compPreference.options, data);
  }

  function getSensorSelected() {
    // Fix issue
    const sensorsSelected = [
      { sensor: "phycocyanin", selected: compPreference.options.phycocyaninSelectedcomp, text: "Phycocyanin (PC)", k1: parseFloat(compPreference.options.phycocyaninK1comp), k2: parseFloat(compPreference.options.phycocyaninK2comp), c1: parseFloat(compPreference.options.phycocyaninC1comp), c2: parseFloat(compPreference.options.phycocyaninC2comp), t1: parseFloat(compPreference.options.phycocyaninT1), variance: 0 },
      { sensor: "chlorA", selected: compPreference.options.chlorASelectedcomp, text: "Chlorophyll (CA)", k1: parseFloat(compPreference.options.chlorAK1comp), k2: parseFloat(compPreference.options.chlorAK2comp), c1: parseFloat(compPreference.options.chlorAC1comp), c2: parseFloat(compPreference.options.chlorAC2comp), t1: parseFloat(compPreference.options.chlorAT1), variance: 0  },
      { sensor: "turbidity", selected: compPreference.options.turbiditySelectedcomp, text: "Turbidity (TU)", k1: (compPreference.options.turbidityK1comp), k2: parseFloat(compPreference.options.turbidityK2comp), c1: parseFloat(compPreference.options.turbidityC1comp), c2: parseFloat(compPreference.options.turbidityC2comp), t1: parseFloat(compPreference.options.turbidityT1), variance: 0  }
    ];
    return sensorsSelected;
  }

  return {
    preference,
    fetchPreference,
    updatePreference,
    getSensorSelected,
    fetchCompPreference,
    updateCompPreference
  }
});