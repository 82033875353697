<template>
  <div class="leaked-devices-container">
    <Header />
    <div class="leaked-devices-content">
      <div class="container">
        <div class="header-container">
          <h2>Alerts List</h2>
          <p class="subtitle">Device alerts that may require attention or maintenance</p>
        </div>
        
        <!-- Loading indicator -->
        <div v-if="isLoading" class="loading-container">
          <div class="spinner"></div>
          <p>Loading device alerts...</p>
          <div class="loading-progress">
            <div class="progress-text">
              <span>{{ currentProgress }}%</span>
              <span v-if="currentDevice">Processing: {{ currentDevice }}</span>
            </div>
            <div class="progress-bar-container">
              <div class="progress-bar" :style="{ width: currentProgress + '%' }"></div>
            </div>
            <div class="progress-info">
              <span>{{ processedDevices }} of {{ totalDevices }} devices</span>
            </div>
          </div>
        </div>
        
        <!-- Filter and search controls -->
        <div v-if="!isLoading" class="controls-container">
          <div class="search-container">
            <input 
              type="text" 
              v-model="searchQuery" 
              placeholder="Search devices..." 
              class="search-input"
            />
          </div>
          <div class="filter-container">
            <select v-model="leakTypeFilter" class="filter-select">
              <option value="">All Leak Types</option>
              <option value="leakDetect">Leak Detector</option>
              <option value="uartError">UART Error</option>
            </select>
          </div>
        </div>
        
        <!-- Devices table -->
        <div v-if="!isLoading" class="table-container">
          <table class="devices-table">
            <thead>
              <tr>
                <th @click="sortBy('name')" class="sortable">
                  Device Name
                  <span v-if="sortColumn === 'name'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th @click="sortBy('event')" class="sortable">
                  Alert Type
                  <span v-if="sortColumn === 'event'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th @click="sortBy('value')" class="sortable">
                  Alert Value
                  <span v-if="sortColumn === 'value'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th @click="sortBy('threshold')" class="sortable">
                  Alert Threshold
                  <span v-if="sortColumn === 'threshold'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th @click="sortBy('date')" class="sortable">
                  Date
                  <span v-if="sortColumn === 'date'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th @click="sortBy('time')" class="sortable">
                  Time
                  <span v-if="sortColumn === 'time'" class="sort-indicator">
                    {{ sortDirection === 'asc' ? '▲' : '▼' }}
                  </span>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="alert in filteredAlerts" :key="alert.id" class="device-row">
                <td>{{ alert.name }}</td>
                <td>
                  <span 
                    class="leak-type-badge" 
                    :class="{'leak-detector': alert.event === 'leakDetect', 'uart-error': alert.event === 'uartError'}"
                  >
                    {{ formatAlertType(alert.event) }}
                  </span>
                </td>
                <td>{{ alert.value.toFixed(2) }}</td>
                <td>{{ alert.threshold.toFixed(2) }}</td>
                <td>{{ alert.date }}</td>
                <td>{{ alert.time }}</td>
                <td class="actions-cell">
                  <button class="btn btn-view" @click="viewDeviceDetails(alert.slotId)" title="View Device Details">
                    <img src="/assets/back-icon.svg" width="14" height="14" style="transform: rotate(180deg);" />
                  </button>
                </td>
              </tr>
              <tr v-if="filteredAlerts.length === 0">
                <td colspan="7" class="no-devices">
                  <div v-if="leakAlerts.length === 0">
                    No alerts detected
                  </div>
                  <div v-else>
                    No alerts match your search criteria
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/authStore';
import { deviceRoot } from "../vue-client";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: 'LeakedDevices',
  components: {
    Header,
    Footer
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    
    // State variables
    const isLoading = ref(true);
    const slots = ref([]);
    const leakAlerts = ref([]);
    const currentDevice = ref('');
    const totalDevices = ref(0);
    const processedDevices = ref(0);
    const currentProgress = ref(0);
    
    // Filter and sort state
    const searchQuery = ref('');
    const leakTypeFilter = ref('');
    const sortColumn = ref('date');
    const sortDirection = ref('desc');
    
    // Format alert type for display
    const formatAlertType = (type) => {
      if (type === 'leakDetect') return 'Leak Detector';
      if (type === 'uartError') return 'UART Error';
      return type;
    };
    
    // Fetch all device slots
    const fetchAllSlots = async () => {
      if (!authStore.authUser) {
        console.error('User not authenticated');
        return;
      }
      
      const authString = "Bearer " + authStore.authUser.authToken;
      
      try {
        const response = await fetch(`${deviceRoot}/slots`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authString,
          },
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch slots: ${response.status}`);
        }
        
        const data = await response.json();
        slots.value = data;
        return data;
      } catch (error) {
        console.error('Error fetching slots:', error);
        return [];
      }
    };
    
    // Fetch alerts for a specific slot
    const fetchAlerts = async (slotId) => {
      if (!authStore.authUser) {
        console.error('User not authenticated');
        return [];
      }
      
      const authString = "Bearer " + authStore.authUser.authToken;
      
      try {
        const response = await fetch(`${deviceRoot}/alerts/${slotId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authString,
          },
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch alerts: ${response.status}`);
        }
        
        return await response.json();
      } catch (error) {
        console.error(`Error fetching alerts for slot ${slotId}:`, error);
        return [];
      }
    };
    
    // Get device information for a slot
    const getDeviceInfo = async (slotId) => {
      if (!authStore.authUser) {
        console.error('User not authenticated');
        return null;
      }
      
      const authString = "Bearer " + authStore.authUser.authToken;
      
      try {
        const response = await fetch(`${deviceRoot}/slots/${slotId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: authString,
          },
        });
        
        if (!response.ok) {
          throw new Error(`Failed to fetch device info: ${response.status}`);
        }
        
        return await response.json();
      } catch (error) {
        console.error(`Error fetching device info for slot ${slotId}:`, error);
        return null;
      }
    };
    
    // Process alerts to find leak related alerts
    const processLeakAlerts = (alerts, slotId, deviceName) => {
      const timeZone = dayjs.tz.guess();
      const leakRelatedAlerts = [];
      
      // Filter for leak related alert types
      const leakRelatedTypes = ['leakDetect', 'uartError'];
      
      for (const alert of alerts) {
        if (leakRelatedTypes.includes(alert.sensorName)) {
          leakRelatedAlerts.push({
            id: `${slotId}-${alert.sensorName}-${alert.updatedAt}`,
            slotId: slotId,
            name: deviceName,
            event: alert.sensorName,
            value: alert.sensorValue,
            threshold: alert.threshold,
            date: dayjs.utc(alert.updatedAt).tz(timeZone).format('M/DD/YYYY'),
            time: dayjs.utc(alert.updatedAt).tz(timeZone).format('h:mm A'),
            timeStamp: dayjs.utc(alert.updatedAt).valueOf()
          });
        }
      }
      
      return leakRelatedAlerts;
    };
    
    // Fetch all leak alerts from all slots
    const fetchAllLeakAlerts = async () => {
      isLoading.value = true;
      leakAlerts.value = [];
      processedDevices.value = 0;
      currentProgress.value = 0;
      
      try {
        const allSlots = await fetchAllSlots();
        totalDevices.value = allSlots.length;
        
        for (let i = 0; i < allSlots.length; i++) {
          const slot = allSlots[i];
          
          // Get device info first to show the device name during loading
          const deviceInfo = await getDeviceInfo(slot._id);
          const deviceName = deviceInfo?.name || `Device ${slot._id}`;
          
          // Update loading status
          currentDevice.value = deviceName;
          processedDevices.value = i + 1;
          currentProgress.value = Math.round((processedDevices.value / totalDevices.value) * 100);
          
          // Fetch and process alerts
          const alerts = await fetchAlerts(slot._id);
          const leakRelated = processLeakAlerts(alerts, slot._id, deviceName);
          
          leakAlerts.value = [...leakAlerts.value, ...leakRelated];
        }
        
        // Sort by timestamp (newest first)
        leakAlerts.value.sort((a, b) => b.timeStamp - a.timeStamp);
      } catch (error) {
        console.error('Error fetching leak alerts:', error);
      } finally {
        isLoading.value = false;
      }
    };
    
    // Computed property for filtered and sorted alerts
    const filteredAlerts = computed(() => {
      let alerts = leakAlerts.value;
      
      // Apply search filter
      if (searchQuery.value) {
        const query = searchQuery.value.toLowerCase();
        alerts = alerts.filter(alert => 
          alert.name.toLowerCase().includes(query)
        );
      }
      
      // Apply leak type filter
      if (leakTypeFilter.value) {
        alerts = alerts.filter(alert => alert.event === leakTypeFilter.value);
      }
      
      // Apply sorting
      alerts = [...alerts].sort((a, b) => {
        let aValue = a[sortColumn.value];
        let bValue = b[sortColumn.value];
        
        // Handle string comparisons
        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
        
        if (sortDirection.value === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
      
      return alerts;
    });
    
    // Sort function
    const sortBy = (column) => {
      if (sortColumn.value === column) {
        // Toggle direction if clicking the same column
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
      } else {
        // Set new column and default to ascending
        sortColumn.value = column;
        sortDirection.value = 'asc';
      }
    };
    
    // View device details
    const viewDeviceDetails = (slotId) => {
      router.push(`/slots/${slotId}`);
    };
    
    // Load data on component mount
    onMounted(() => {
      fetchAllLeakAlerts();
    });
    
    return {
      isLoading,
      leakAlerts,
      filteredAlerts,
      searchQuery,
      leakTypeFilter,
      sortColumn,
      sortDirection,
      formatAlertType,
      sortBy,
      viewDeviceDetails,
      currentDevice,
      totalDevices,
      processedDevices,
      currentProgress
    };
  }
});
</script>

<style scoped>
.leaked-devices-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.leaked-devices-content {
  flex: 1;
  padding: 1.5rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.header-container {
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  color: #2c3e50;
}

.subtitle {
  color: #6c757d;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.controls-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.search-container {
  width: 250px;
}

.search-input {
  padding: 0.35rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 0.85rem;
}

.filter-container {
  padding: 0.35rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 0.85rem;
}

.filter-select {
  padding: 0.35rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 0.85rem;
}

.table-container {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.devices-table {
  width: 100%;
  border-collapse: collapse;
}

.devices-table th {
  background-color: #f8f9fa;
  padding: 0.4rem 0.5rem;
  text-align: left;
  font-weight: 600;
  font-size: 0.8rem;
  color: #495057;
  border-bottom: 1px solid #dee2e6;
  position: relative;
  height: 2.2rem;
}

.sortable {
  cursor: pointer;
}

.sort-indicator {
  position: absolute;
  right: 0.5rem;
  font-size: 0.75rem;
}

.devices-table td {
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  font-size: 0.8rem;
  height: 2.2rem;
  vertical-align: middle;
}

.leak-type-badge {
  display: inline-block;
  padding: 0.1rem 0.35rem;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.2;
}

.leak-detector {
  background-color: #ffecb3;
  color: #856404;
}

.uart-error {
  background-color: #f8d7da;
  color: #721c24;
}

.actions-cell {
  white-space: nowrap;
  width: 60px;
  text-align: center;
}

.btn {
  padding: 0.2rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  line-height: 1;
  min-width: 24px;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-view {
  background-color: #e3f2fd;
  color: #0d6efd;
}

.btn-view:hover {
  background-color: #d0e7fa;
}

.no-devices {
  text-align: center;
  padding: 1rem 0;
  color: #6c757d;
  font-style: italic;
  font-size: 0.85rem;
}

.loading-progress {
  width: 90%;
  max-width: 500px;
  margin-top: 20px;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}

.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  transition: width 0.3s ease;
}

.progress-info {
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}
</style> 