import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import { usePreferenceStore } from '../stores/preferenceStore';
dayjs.extend(utc);
dayjs.extend(tz);

class SensorEntry {
    val: number;
    name: string;
    handshakeTime: string;
    timestamp: Date;
    interpolated: boolean;
}


class SensorSnapshot {
    sensorName: string = "";
    value: number = 0;
}

// For a particular timestamp, populate the corresponding sensor values
class SensorsSnapshot {
    timestamp: number = 0;
    sensors: Array<SensorSnapshot> = [];

    exportHeader() {
        let timeString: string = 'timeString';
        const sensorNames: Array<string> = [timeString];
        this.sensors.forEach((sensor) => {
            sensorNames.push(sensor.sensorName);
        });

        const header = sensorNames.join(",");
        return "," + header; // To be consistent with sensor values
    }

    exportSensorValues(deviceZone: any) {
        let timeStringValue: string = '';
        if (deviceZone.status === "OK") {
            timeStringValue = dayjs.tz(this.timestamp * 1000, deviceZone.timeZoneId).format("MM/DD/YY HH:mm:ss");
        } else {
            timeStringValue = dayjs(this.timestamp * 1000).format("MM/DD/YY HH:mm:ss");
        }
        const sensorValues: Array<string> = [timeStringValue.toString()];
        this.sensors.forEach((sensor) => {
            sensorValues.push(sensor.value.toString());
        });

        const values = sensorValues.join(",");
        return values;
    }
}

export class ExportUtility {
    public static computeSensorValues(slotHistory: Array<SensorEntry>) {
        // Group eensors by name
        const sensorMap = new Map<string, Array<SensorEntry>>();
        slotHistory.forEach((entry: SensorEntry) => {
            if (!sensorMap.has(entry.name)) {
                sensorMap.set(entry.name, []);
            }
            sensorMap.get(entry.name).push(entry);
        });

        // Sort each group by timestamp
        sensorMap.forEach((entries: Array<SensorEntry>) => {
            entries.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
        });

        const utcTimeEntries = sensorMap.get('utcTime');
        if (utcTimeEntries) {
            const utcTimeTimestamps = utcTimeEntries.map(entry => entry.timestamp);
            // Function to find the closest entry based on timestamp
            const findClosestEntry = (entries: SensorEntry[], timestamp: Date): SensorEntry => {
                let closest = entries[0];
                let closestDiff = Math.abs(entries[0].timestamp.getTime() - timestamp.getTime());
                for (let entry of entries) {
                    let diff = Math.abs(entry.timestamp.getTime() - timestamp.getTime());
                    if (diff < closestDiff) {
                        closest = entry;
                        closestDiff = diff;
                    }
                }
                return closest;
            };

            // Function to ensure each sensor has entries for all utcTime timestamps
            const ensureMatchingTimestamps = (entries: SensorEntry[], timestamps: Date[]): SensorEntry[] => {
                const result: SensorEntry[] = [];
                for (let timestamp of timestamps) {
                    const matchingEntry = entries.find(entry => entry.timestamp.getTime() === timestamp.getTime());
                    if (matchingEntry) {
                        result.push(matchingEntry);
                    } else {
                        const closestEntry = findClosestEntry(entries, timestamp);
                        closestEntry.interpolated = true;
                        result.push({ ...closestEntry, timestamp });
                    }
                }
                return result;
            };

            // Clean up each sensor to match the "utcTime" timestamps
            sensorMap.forEach((entries, sensorName) => {
                if (sensorName !== 'utcTime') {
                    const updatedEntries = ensureMatchingTimestamps(entries, utcTimeTimestamps);
                    sensorMap.set(sensorName, updatedEntries);
                }
            });

        }

        return sensorMap;
    }

    public static getV0Histories(sensorMap: Map<string, Array<SensorEntry>>, slotId: string) {
        const results = {
            slotId: slotId,
            sensors: []
        };

        // Sort based on sensorName
        const sensorNames = [
            "utcTime",
            "light",
            "airTemp",
            "waterTemp",
            "turbidity",
            "phycocyanin",
            "chlorA",
            "waterLight",
            "buoyTemp",
            "latitude",
            "longitude",
            "battVolts",
            "battSOC",
            "sigStrength",
            "sigQuality",
            "codeVersion",
            "panelVolts",
            "leakDetect",
            "fixOk",
            "brushError",
            "uartError",
            "ph",
            "do",
            "ps",
            "windSpeed",
            "precipitationIntensity"];
        const utcTimeEntries = sensorMap.get('utcTime') as Array<SensorEntry>;
        if (utcTimeEntries) {
            const numOfEntries = utcTimeEntries.length;

            sensorNames.forEach((sensorName) => {
                const sensorHistory = {
                    sensorName: sensorName,
                    history: Array(numOfEntries).fill({ val: -1 })
                };
                const entries = sensorMap.get(sensorName) as Array<SensorEntry>;
                if (entries?.length > 0) {
                    sensorHistory.history = entries.map(entry => ({ val: entry.val }));
                }

                (results.sensors as any).push(sensorHistory);
            });
        }

        return results;
    }
    // sensorNames is a filter. We will only export the sensors that are in this list.
    // Return array of lines
    public static exportToCsv(slotHistory: Array<SensorEntry>, sensorNames: Array<string>, deviceZone: any, trendLineProperties: any): Array<string> {
        // Group eensors by name
        const sensorMap = ExportUtility.computeSensorValues(slotHistory);
        if (sensorMap.size === 0) {
            return [];
        }
        const preferenceStore = usePreferenceStore();
        const sensorsSelected = preferenceStore.getSensorSelected();
        sensorsSelected.forEach((sensorSelected) => {
            const sensorName = sensorSelected.sensor;
            const k1 = sensorSelected.k1;
            const k2 = sensorSelected.k2;
            const c1 = sensorSelected.c1;
            const c2 = sensorSelected.c2;
            const t1 = sensorSelected.t1;
            // Loop through each sensor and apply compensation
            const sensor = sensorMap.get(sensorName);
            const compSensorName = 'waterTemp';
            const compSensor = sensorMap.get(compSensorName);
            if (sensor !== undefined && sensorSelected.selected) {
                sensor.forEach((entry) => {
                    const sensorValue = entry.val;
                    if (compSensor !== undefined) {
                        const compSensorValue = compSensor.find((item) => item.timestamp.getTime() === entry.timestamp.getTime())?.val;
                        if (compSensorValue !== undefined) {
                            const k = (k2 - k1) * (sensorValue - c1) / (c2 - c1) + k1;
                            let compValue = sensorValue - k * (compSensorValue - t1);
                            if (compValue < 0) {
                                compValue = 0;
                            }
                            entry.val = compValue;
                        }
                    }
                });
            }
        });


        ExportUtility.populateTrendValues(sensorMap, 'phycocyanin', 'phycocyaninTrend', trendLineProperties.pcProperties.kl, trendLineProperties.pcProperties.maxLight, trendLineProperties.pcProperties.alpha);
        ExportUtility.populateTrendValues(sensorMap, 'chlorA', 'chlorATrend', trendLineProperties.caProperties.kl, trendLineProperties.caProperties.maxLight, trendLineProperties.caProperties.alpha);
        ExportUtility.populateTrendValues(sensorMap, 'turbidity', 'turbidityTrend', trendLineProperties.tuProperties.kl, trendLineProperties.tuProperties.maxLight, trendLineProperties.tuProperties.alpha);

        const str: Array<string> = [];
        const header = ExportUtility.exportHeader(sensorNames);
        str.push(header);
        str.push("\n");

        const utcTimeEntries = sensorMap.get('utcTime');
        const utcTimeTimestamps = utcTimeEntries.map(entry => entry.timestamp);

        utcTimeTimestamps.forEach((entry, index) => {
            const sensorValues: Array<string> = [];
            sensorNames.forEach((sensorName) => {
                const entries = sensorMap.get(sensorName) as Array<SensorEntry>;
                if (sensorName === 'timeString') {
                    // Entry is local time. We need to convert to device time
                    let timeStringValue: string;
                    if (deviceZone.status === "OK") {
                        timeStringValue = dayjs.tz(entry, deviceZone.timeZoneId).format("MM/DD/YY HH:mm:ss");
                    }
                    else {
                        timeStringValue = dayjs(entry).format("MM/DD/YY HH:mm:ss");
                    }

                    sensorValues.push(timeStringValue);
                }else if (sensorName === 'fixOk') {
                    let value = 0
                    if (entries === undefined || entries[index] === undefined || entries[index].val === undefined) {
                        value = 0
                    }
                    else {
                        value = entries[index].val;
                    }
                    sensorValues.push(value === 1 ? 'GPS locked' : 'No GPS lock')
                }
                else {
                    if (entries === undefined || entries[index] === undefined || entries[index].val === undefined) {
                        sensorValues.push('0');
                    }
                    else {
                        const sensorValue = entries[index].val;
                        sensorValues.push(sensorValue.toString());
                    }
                }
            });

            const values = sensorValues.join(",");
            str.push(values);
            str.push("\n");
        });

        return str;
    }

    public static lowpassFilter(sensorValues: Array<SensorEntry>, alpha: number): void {
        let outDat: Array<number> = new Array<number>(sensorValues.length);
        for (let i = 0; i < sensorValues.length; i++) {
            if (i == 0) {
                outDat[i] = sensorValues[i].val;
            } else {
                outDat[i] = (1 - alpha) * sensorValues[i].val + alpha * outDat[i - 1];
            }
        }

        for (let i = 0; i < sensorValues.length; i++) {
            sensorValues[i].val = outDat[i];
        }
    }

    public static populateTrendValues(sensorMap: Map<string, Array<SensorEntry>>, rawName: string, trendName: string, k: number, maxLight: number, alpha: number) {
        if (maxLight === undefined) {
            maxLight = Number.MAX_VALUE;
        }

        const lightEntries = sensorMap.get('light') as Array<SensorEntry>;
        const rawEntries = sensorMap.get(rawName) as Array<SensorEntry>;;
        const sampleSize = lightEntries.length;
        const trendSensorEntries: Array<SensorEntry> = [];
        for (let i = 0; i < sampleSize; i++) {
            const light = lightEntries[i].val;
            const raw = rawEntries[i].val;
            let trendValue = 0;
            if (i === 0) {
                trendValue = raw + k * Math.min(light, maxLight);
            }
            else {
                const lightPrevious = lightEntries[i - 1].val;
                trendValue = raw + k * Math.min(lightPrevious, maxLight);
            }

            const sensorEntry: SensorEntry = { val: trendValue, handshakeTime: '', name: trendName, timestamp: lightEntries[i].timestamp, interpolated: true };
            trendSensorEntries.push(sensorEntry);
        }

        // Now let's do lower pass filter
        ExportUtility.lowpassFilter(trendSensorEntries, alpha);
        sensorMap.set(trendName, trendSensorEntries);
    }

    public static exportHeader(sensorNames: Array<string>): string {
        // Replace airTemp with testVal
        sensorNames = sensorNames.map((sensorName) => {
            if (sensorName === 'airTemp') {
                return 'testVal';
            }
            if (sensorName === 'fixOk') {
                return 'GPS Lock'
            }
            return sensorName;
        });
        const header = sensorNames.join(",");
        return "," + header; // To be consistent with sensor values
    }
}